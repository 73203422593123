var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-customer-contacts"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('contacts')))])]), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-row', {
    staticClass: "py-10"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    staticClass: "mt-2"
  }, [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": _vm.$route.params.customerUUID === 'BCM' ? 'orange' : 'privateSupplier'
    }
  }, [_vm._v(" fas fa-warehouse ")]), _c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v(" " + _vm._s(_vm.customer.name) + " ")])], 1), _c('v-card-text', [_c('div', {
    staticClass: "font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.customer.address.company) + " ")]), _vm.customer.address.firstname ? _c('div', [_vm._v(" " + _vm._s(_vm.customer.address.firstname) + " ")]) : _vm._e(), _vm.customer.address.lastname ? _c('div', [_vm._v(" " + _vm._s(_vm.customer.address.lastname) + " ")]) : _vm._e(), _c('div', [_vm._v(" " + _vm._s(_vm.customer.address.streetAddress) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.customer.address.zipCode) + " " + _vm._s(_vm.customer.address.city) + " ")]), _vm.customer.address.state ? _c('div', [_vm._v(" " + _vm._s(_vm.customer.address.state) + " ")]) : _vm._e(), _c('div', [_vm._v(" " + _vm._s(_vm.$t("countries.".concat(_vm.customer.address.country))) + " ")]), _vm.customer.address.VATnumber ? _c('div', [_vm._v(" " + _vm._s(_vm.customer.address.VATnumber) + " ")]) : _vm._e(), _vm.customer.address.phoneNumber ? _c('div', [_vm._v(" " + _vm._s(_vm.customer.address.phoneNumber) + " ")]) : _vm._e()])], 1)], 1), _vm._l(_vm.customerUsers, function (customerUser, index) {
    var _customerUser$communi;

    return _c('v-col', {
      key: index
    }, [_c('v-card', {
      staticClass: "mt-2"
    }, [_c('v-card-title', [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "color": _vm.$route.params.customerUUID === 'BCM' ? 'orange' : 'privateSupplier'
      }
    }, [_vm._v(" fas fa-user-alt ")]), _c('span', {
      staticClass: "text-subtitle-2"
    }, [_vm._v(" " + _vm._s(customerUser === null || customerUser === void 0 ? void 0 : customerUser.firstname) + " " + _vm._s(customerUser === null || customerUser === void 0 ? void 0 : customerUser.lastname) + " ")]), _c('v-spacer'), _c('flag', {
      staticStyle: {
        "height": "15px"
      },
      attrs: {
        "iso": (customerUser === null || customerUser === void 0 ? void 0 : (_customerUser$communi = customerUser.communicationLanguage) === null || _customerUser$communi === void 0 ? void 0 : _customerUser$communi.toLowerCase()) === 'en' ? 'gb' : 'fr'
      }
    })], 1), _c('v-card-text', [_c('div', [_c('v-icon', {
      staticClass: "mr-3",
      attrs: {
        "x-small": ""
      }
    }, [_vm._v(" fas fa-envelope ")]), customerUser !== null && customerUser !== void 0 && customerUser.email ? _c('a', {
      attrs: {
        "href": "mailto:".concat(customerUser === null || customerUser === void 0 ? void 0 : customerUser.email),
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(_vm._s(customerUser === null || customerUser === void 0 ? void 0 : customerUser.email))]) : _vm._e()], 1), _c('div', [_c('v-icon', {
      staticClass: "mr-3",
      attrs: {
        "x-small": ""
      }
    }, [_vm._v(" fas fa-phone-alt ")]), customerUser !== null && customerUser !== void 0 && customerUser.phone ? _c('a', {
      attrs: {
        "href": "tel:".concat(customerUser === null || customerUser === void 0 ? void 0 : customerUser.phone.replace(/\s+/g, '')),
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(_vm._s(customerUser === null || customerUser === void 0 ? void 0 : customerUser.phone))]) : _vm._e()], 1)])], 1)], 1);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }