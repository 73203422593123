<template>
  <div class="page-customer-contacts">
    <v-row justify="space-between" align="center">
      <span class="text-wrap display-1 ma-3">{{ $t('contacts') }}</span>
    </v-row>
    <v-divider class="mt-2 mb-4"></v-divider>
    <v-row class="py-10">
      <v-col cols="4">
        <v-card class="mt-2">
          <v-card-title>
            <v-icon :color="$route.params.customerUUID === 'BCM' ? 'orange' : 'privateSupplier'" class="mr-2">
              fas fa-warehouse
            </v-icon>
            <span class="text-subtitle-2">
              {{ customer.name }}
            </span>
          </v-card-title>
          <v-card-text>
            <div class="font-weight-medium">
              {{ customer.address.company }}
            </div>
            <div v-if="customer.address.firstname">
              {{ customer.address.firstname }}
            </div>
            <div v-if="customer.address.lastname">
              {{ customer.address.lastname }}
            </div>
            <div>
              {{ customer.address.streetAddress }}
            </div>
            <div>
              {{ customer.address.zipCode }} {{ customer.address.city }}
            </div>
            <div v-if="customer.address.state">
              {{ customer.address.state }}
            </div>
            <div>
              {{ $t(`countries.${customer.address.country}`) }}
            </div>
            <div v-if="customer.address.VATnumber">
              {{ customer.address.VATnumber }}
            </div>
            <div v-if="customer.address.phoneNumber">
              {{ customer.address.phoneNumber }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-for="(customerUser, index) in customerUsers"
        :key="index"
      >
        <v-card class="mt-2">
          <v-card-title>
            <v-icon :color="$route.params.customerUUID === 'BCM' ? 'orange' : 'privateSupplier'" class="mr-2">
              fas fa-user-alt
            </v-icon>
            <span class="text-subtitle-2">
              {{ customerUser?.firstname }} {{ customerUser?.lastname }}
            </span>
            <v-spacer></v-spacer>
            <flag style="height:15px" :iso="customerUser?.communicationLanguage?.toLowerCase() === 'en' ? 'gb' : 'fr'"></flag>
          </v-card-title>
          <v-card-text>
            <div>
              <v-icon x-small class="mr-3">
                fas fa-envelope
              </v-icon>
              <a
                v-if="customerUser?.email"
                :href="`mailto:${customerUser?.email}`"
                target="_blank"
                rel="noopener noreferrer"
              >{{ customerUser?.email }}</a>
            </div>
            <div>
              <v-icon x-small class="mr-3">
                fas fa-phone-alt
              </v-icon>
              <a
                v-if="customerUser?.phone"
                :href="`tel:${customerUser?.phone.replace(/\s+/g, '')}`"
                target="_blank"
                rel="noopener noreferrer"
              >{{ customerUser?.phone }}</a>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

const i18nData = require('./pageCustomerContacts.i18n.json');

export default {
  name: 'PageCustomerContacts',
  components: {
  },
  props: {
    /**
     * Customer
     */
    customer: {
      type: Object,
      default: null
    },
    /**
     * Users
     */
    customerUsers: {
      type: Array,
      default: null
    },
    /**
     * Quotes
     */
    customerQuotes: {
      type: Array,
      default: null
    },
    /**
     * Orders
     */
    customerOrders: {
      type: Array,
      default: null
    },
    /**
     * Parts
     */
    customerParts: {
      type: Array,
      default: null
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
};
</script>

